import { AxiosResponse } from "axios";

const getFileNameFromAxiosResponse = (response: AxiosResponse): string => {
    var disposition = response.headers["content-disposition"];
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    const fileName = matches?.length ? matches[1].replace(/['"]/g, '') : '';
    return fileName;
}

export const axiosDownload = (response: AxiosResponse) => {
    const fileName = getFileNameFromAxiosResponse(response);
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export const getErrorMessages = (errorResponse: any) => {
    if (!errorResponse)
        return "";
    const errorsData = getErrorsData(errorResponse);
    if (!errorsData || Object.entries(errorsData).length === 0) {
        return "";
    }
    const messages = [].concat.apply([], Object.entries(errorsData).map((x: any) => x[1]));
    return messages.join("\n");
}

export const getErrorsData = (errorResponse: any) => {
    if (errorResponse.data.errors) {
        return errorResponse.data.errors;
    }
    if (errorResponse.data) {
        const message = errorResponse.data.message || errorResponse.data.detail || errorResponse.data.title;
        if (message)
            return { message };
        else
            return errorResponse.data;
    }
    return { message: errorResponse.statusText };
};

export const addBodyClass = (className: string[]) => document.body.classList.add(...className);
export const removeBodyClass = (className: string[]) => document.body.classList.remove(...className);