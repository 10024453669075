import axios from 'axios';

export const getGetDebtor = (locale: string) => {
    return axios.get(`/portal/GetDebtor?locale=${locale}`);
}

export const getPaymentType = (paymentTypeCd: number, locale: string) => {
    return axios.get(`/portal/PaymentType?paymentTypeCd=${paymentTypeCd == null ? "" : paymentTypeCd}&locale=${locale}`);
}

export const setMessageRead = (messageId: number) => {
    const retrievedData = getCookie(`jf_message_read_ids`) || '[]';
    var messageReadIds = JSON.parse(retrievedData);
    messageReadIds.push(messageId);
    setCookie(`jf_message_read_ids`, JSON.stringify(messageReadIds), 12);
}

export const getMessageReadIds = (userId: string) => {
    const retrievedData = getCookie(`jf_message_read_ids`) || '[]';
    return JSON.parse(retrievedData);
}

function setCookie(cname: string, cvalue: any, hours: number) {
    var d = new Date();
    d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}