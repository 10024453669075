import React from 'react';
import { Layout } from './components/Layout';
import { LocalizedRouter } from './components/i18n/components/LocalizedRouter';
import { BrowserRouter, Route } from 'react-router-dom';
import { AppLanguage, AppRoute } from "./const";
import { LocalizedSwitch, appStrings } from './components/i18n';
import MySpace from 'components/MySpace';
import AccessDenied from 'components/AccessDenied';
import Login from 'components/Login';
import PrivateRoute from 'components/PrivateRoute';
import Home from 'components/Home';

const defaultLocale = (localStorage.getItem("my-language") || AppLanguage.French) as AppLanguage;

const App: React.FC = () => {
  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
      defaultLanguage={defaultLocale}
    >
      <Layout>
        <LocalizedSwitch>
          <Route exact path={AppRoute.Login} component={Login} />
          <Route path={AppRoute.AccessDenied} component={AccessDenied} />
          <PrivateRoute exact path={AppRoute.MySpace} component={MySpace} />
          <PrivateRoute exact path={AppRoute.Home} component={Home} />
        </LocalizedSwitch>
      </Layout>
    </LocalizedRouter>
  );
}

export default App;