import React from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Redirect } from 'react-router-dom';
import { AppLanguage } from '../../../const';

import { LanguageStrings } from '../localizations';

interface Props {
    RouterComponent: React.ComponentClass<any>;
    languages: { [k: number]: string };
    appStrings: { [prop: string]: LanguageStrings };
    defaultLanguage?: AppLanguage;
}

export const LocalizedRouter: React.FC<Props> = ({
    children,
    RouterComponent,
    appStrings,
    defaultLanguage
}) => (
    <RouterComponent>
        <Route path="/:lang([a-z]{2})">
            {({ match, location }) => {
                const params: any = match ? match.params : {};
                const lang = params.lang || defaultLanguage || AppLanguage.English;
                const _lang = lang?.toLowerCase();
                const { pathname, search } = location;
                if (!pathname.startsWith(`/${lang}/`) && pathname !== `/${lang}`) {
                    return <Redirect to={`/${lang}${pathname}${search}`} />;
                }
                return (
                    <IntlProvider locale={_lang} messages={appStrings[_lang]}>
                        {children}
                    </IntlProvider>
                );
            }}
        </Route>
    </RouterComponent>
);
