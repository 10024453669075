import React, { useEffect } from 'react'
import { useAuth } from './useProvideAuth'
import { Loader } from 'semantic-ui-react';

interface Props {

}

const Login: React.FC<Props> = (props: Props) => {
    const auth = useAuth();
    useEffect(() => {
        auth.login()
    }, [])
    return (<Loader inline />)
}

export default Login
