import 'semantic-ui-css/semantic.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/fr'
import './custom.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import App from './App';
import { AuthProvider } from 'components/useProvideAuth';
import * as serviceWorker from './serviceWorker';
import ReactGA from "react-ga4";
import { ToastProvider } from 'react-toast-notifications';

//Set Google Analytics tag
ReactGA.initialize('G-8SR9439WEF');

serviceWorker.unregister();

const url = new URL(window.location.href);
const loggedIn = url.searchParams.get("loggedin") === "true";

ReactDOM.render(
    <ToastProvider>
        <AuthProvider loggedIn={loggedIn}>
            <App />
        </AuthProvider>
    </ToastProvider>
    , document.getElementById('root'));

