import React from 'react'
import { Message, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const AccessDenied = () => {
    return (
        <Message color="red" className="alert" >
            <Icon name="warning circle" size="large" /> <FormattedMessage id="accessDenied"
                values={{ email: <a href="mailto:info@jeanfortin.com"><q>info@jeanfortin.com</q></a> }} />
        </Message>
    );
}

export default AccessDenied
