import React from 'react';
import { AppLanguage } from '../../../const';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Menu, Dropdown } from 'semantic-ui-react';

export const LanguageSwitcher: React.FC = () => {
    const { pathname, search } = useLocation();
    const { locale } = useIntl();
    const intl = useIntl();

    return (
        <>
            <Dropdown className="langage-dropdown" item text={intl.formatMessage({ id: locale })} direction="left" pointing="top right">
                <Dropdown.Menu>
                    {Object.keys(AppLanguage).map(lang => (
                        <Menu.Item key={lang} as={NavLink} to={getMatchingRoute(AppLanguage[lang])}>
                            {intl.formatMessage({ id: AppLanguage[lang] })}
                        </Menu.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );

    function getMatchingRoute(language: string) {
        let route = pathname;
        const localeRoute = `/${locale}`;
        if (pathname.startsWith(localeRoute)) {
            route = pathname.substring(pathname.indexOf(`/${locale}`) + localeRoute.length);
        }
        return `/${language}` + route + search;
    }
};
