import React from 'react'

interface Props {

}

const Home = (props: Props) => {
    return (
        <div>Home Page</div>
    )
}

export default Home
