import React from "react";
import { Message, MessageProps } from "semantic-ui-react";

interface Props extends MessageProps {
    errors: any;
}

const MessageError = (props: Props) => {
    const { errors, ...messageProps } = props;
    if (!errors || Object.entries(errors).length === 0) {
        return (<></>);
    }
    const messages = [].concat.apply([], Object.entries(errors).map((x: any) => x[1]));
    return (
        <Message error {...messageProps}>
            <Message.List>
                {messages.map((msg, i) => <Message.Item key={i}>{<span dangerouslySetInnerHTML={{ __html: msg }} />}</Message.Item>)}
            </Message.List>
        </Message>
    );
};

export default MessageError;