import React from 'react'

interface Props {
    children: React.ReactNode
}
const HtmlSpace = (props: Props) => {
    const text = props.children?.toString().replace(/\s+/g, " ");
    return (<>{text}</>)
}

export default HtmlSpace
