import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { AppRoute } from 'const';
import { useAuth } from 'components/useProvideAuth';
import Spinner from './Spinner';


const PrivateRoute = ({ component: Component, path }: RouteProps) => {
    const auth = useAuth();

    if (auth.loginInProgress) {
        return (<Spinner />);
    }

    if (auth.error) {
        return <Redirect to={AppRoute.Error} />;
    }

    if (!auth.isAuthenticated) {
        return <Redirect to={AppRoute.Login} />;
    }

    return <Route component={Component} path={path} />;
};

export default PrivateRoute