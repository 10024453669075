export const formatPhoneNumber = (phone: string) => {
    var formatPart = phone.substring(0, 10);
    if (isNumeric(formatPart) && phone.length > 9) {
        return phone.substring(0, 3) + " " + phone.substring(3, 6) + "-" + phone.substring(6, phone.length);
    }
    return phone;
}

function isNumeric(str: string) {
    return /^\d+$/.test(str);
}