import React, { FunctionComponent, useContext, useState } from 'react';
import { Container, Menu, Image, Visibility, Icon, Dropdown, Button, Card, Label, Responsive } from 'semantic-ui-react';
import { LanguageSwitcher } from './i18n';
import { FormattedMessage } from 'react-intl';
import Avatar from 'react-avatar';
import { DeviceContext, DeviceType } from '../utils/Contexts';

const shadeMenuStyle = {
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    margin: 0,
    opacity: 0
}

const firstMenuStyle = {
    borderRadius: 0,
    marginBottom: '0em',
    backgroundColor: '#0a0a39'
}

interface Props {
    isAuthenticated: boolean;
    loggedin: boolean;
    user: any;
    login(): void;
    logOut(): void;
}

export const TopMenu: FunctionComponent<Props> = (props) => {
    const [menuFixed, setMenuFixed] = useState(false);
    const diplayName = props.isAuthenticated ? props.user.name : "";
    const device = useContext(DeviceContext);
    const isMobile = device === DeviceType.Mobile;
    return (<>
        <Visibility once={false}
            onBottomPassed={() => setMenuFixed(true)}
            onBottomVisible={() => setMenuFixed(false)}
        >
            <Menu borderless style={firstMenuStyle}>
                <Container>
                    {isMobile &&
                        <Menu.Item header style={{ color: "white" }}>
                            <FormattedMessage id="topMenu.title" />
                        </Menu.Item>
                    }
                    {props.isAuthenticated ?
                        <>
                            <Menu.Item as="a" name="logout" onClick={() => props.logOut()} position="right" style={{ color: "white" }}>
                                <FormattedMessage id="logout" />
                            </Menu.Item>
                        </>
                        : <></>
                    }
                </Container>
            </Menu>
            <Menu borderless
                className={`top-menu ${menuFixed ? 'fixed' : ''}`}
                fixed={menuFixed ? 'top' : undefined}>
                <Container>
                    <Menu.Item className="topMenuIcon">
                        <Image src={process.env.PUBLIC_URL + (!isMobile ? '/favicon-191x48.png' : '/cropped-favicon-192x192.png')}
                            style={{ height: 48 }} />
                    </Menu.Item>
                    {!isMobile &&
                        <Menu.Item header as="h2" className="topMenuTitle">
                            <Responsive as="span" minWidth={400}>
                                <FormattedMessage id="topMenu.title" />
                            </Responsive>
                        </Menu.Item>
                    }
                    <Menu.Menu position='right'>
                        {props.isAuthenticated ?
                            <>
                                <Menu.Item>
                                    <LanguageSwitcher />
                                </Menu.Item>
                                <Menu.Item>
                                    <Icon size="large" name="alarm" color="blue" />
                                    {props.user.messageCount > 0 &&
                                        <Label
                                            className="label-on-corner"
                                            color="red"
                                            size={"mini"}
                                            floating
                                            circular>
                                            {props.user.messageCount}
                                        </Label>
                                    }
                                </Menu.Item>
                                <Dropdown item icon={false}
                                    trigger={<Avatar size='32' round={true} name={diplayName} textSizeRatio={2.3} className="topMenuAvatar" color="#162a43" />} title={diplayName}>
                                    <Dropdown.Menu>
                                        <Dropdown.Header>
                                            <Card style={{ border: 'none', boxShadow: 'none' }}>
                                                <Card.Content textAlign="center">
                                                    <Card.Description>
                                                        <Avatar size='96' round={true} name={diplayName} color="#162a43" />
                                                    </Card.Description>
                                                    <Card.Description style={{ marginTop: 15 }}>{diplayName}</Card.Description>
                                                </Card.Content>
                                                <Card.Content extra textAlign="right">
                                                    <Button primary icon onClick={() => props.logOut()} >
                                                        <Icon name='sign out' /> <FormattedMessage id="logout" />
                                                    </Button>
                                                </Card.Content>
                                            </Card>
                                        </Dropdown.Header>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                            :
                            <>
                                <Menu.Item>
                                    <LanguageSwitcher />
                                </Menu.Item>
                                {!props.loggedin ?

                                    <Menu.Item as="a" name="login" onClick={() => props.login()}>
                                        <Icon name='sign in' /> <FormattedMessage id="login" />
                                    </Menu.Item>
                                    :
                                    <Menu.Item as="a" name="logout" onClick={() => props.logOut()}>
                                        <Icon name='sign out' /> <FormattedMessage id="logout" />
                                    </Menu.Item>
                                }
                            </>
                        }
                    </Menu.Menu>
                </Container>
            </Menu>
        </Visibility>
        <Menu borderless style={{ ...shadeMenuStyle, display: menuFixed ? 'unset' : 'none' }} >
            <Container>
                <Menu.Item>
                    <Image src={process.env.PUBLIC_URL + '/favicon-32x32.png'} />
                </Menu.Item>
            </Container>
        </Menu>
    </>
    );
};