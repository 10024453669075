import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { RouteProps, Switch } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { AppLanguageCultures } from "const";
import * as authService from '../../../services/AuthService';

export const LocalizedSwitch: React.FC = ({ children }) => {
    const { locale } = useIntl();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setCulture = async () => {
            setLoading(true);
            try {
                localStorage.setItem("my-language", locale)
                await authService.setCulture(AppLanguageCultures.get(locale));
            } catch (error) { }
            finally {
                setLoading(false);
            }
        };
        setCulture();
    }, [locale])

    if (loading) {
        return (<Loader active inline='centered' />);
    }

    return (
        <Switch>
            {React.Children.map(children, child =>
                React.isValidElement<RouteProps>(child)
                    ? React.cloneElement(child, {
                        ...child.props,
                        path: localizeRoutePath(child.props.path?.toString())
                    })
                    : child
            )}
        </Switch>
    );

    function localizeRoutePath(path?: string | string[]) {
        switch (typeof path) {
            case 'undefined':
                return undefined;
            case 'object':
                return path.map(key => `/${locale}` + key);
            default:
                const isFallbackRoute = path === '*';
                return isFallbackRoute
                    ? path
                    : `/${locale}` + path;
        }
    }
};