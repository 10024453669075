import React, { useState, useEffect, useCallback, useContext } from 'react'
import { Grid, List, Progress, Popup, Card, Button, Segment, Form, Menu, Icon } from 'semantic-ui-react'
import Alert from './Alert'
import * as portalService from '../services/PortalService'
import * as documentService from '../services/DocumentService'
import Spinner from './Spinner'
import { Redirect } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import Moment from 'react-moment';
import produce from "immer";
import { AppRoute } from 'const';
import { useAuth } from './useProvideAuth'
import { formatPhoneNumber } from 'services/Util'
import HtmlSpace from './HtmlSpace'
import { FileRejection, useDropzone } from 'react-dropzone'
import MessageError from './MessageError'
import { AxiosError } from 'axios'
import { getErrorMessages, getErrorsData } from '../utils/Utils'
import { useToasts } from 'react-toast-notifications'
import { confirmAlert } from 'react-confirm-alert';
import { DeviceContext, DeviceType } from '../utils/Contexts'

interface Props {

}

const MySpace: React.FC<Props> = (props: Props) => {
    const { locale } = useIntl();
    const intl = useIntl();
    const [pageLoading, setPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [debtor, setDebtor] = useState<any>();
    const [paymentType, setPaymentType] = useState<any>();
    const [documents, setDocuments] = useState<any>([]);
    const [errors, setErrors] = useState(null);
    const auth = useAuth();
    const { addToast } = useToasts();
    const device = useContext(DeviceContext);
    const isMobile = device === DeviceType.Mobile;

    useEffect(() => {
        (async function () {
            try {
                const resp = await portalService.getGetDebtor(locale);
                const messageReadIds = portalService.getMessageReadIds(auth.user.nameIdentifier);
                let _debtor = resp.data;
                _debtor.message = _debtor.message.filter((x: any) => messageReadIds.indexOf(x.messageId) === -1);
                setDebtor(_debtor);
                auth.setAlertCount(_debtor.message.length);
                const resp2 = await portalService.getPaymentType(resp.data?.case?.paymentTypeCd, locale);
                setPaymentType(resp2.data);
                const docsResp = await documentService.getCurrentUserDocuments();
                setDocuments(docsResp.data);
            } catch (error) {
                const errorMsg = getErrorMessages(error.response);
                addToast(errorMsg, { appearance: "error", autoDismiss: true });
            } finally {
                setPageLoading(false);
            }
        })();
    }, [])

    const getPaymentProgress = (total: number | undefined, amount: number | undefined) => {
        if (!total || !amount) return 0;
        return amount / total * 100
    }

    const getInsolvencyLabel = (administrationTypeCd: number) => {
        switch (administrationTypeCd) {
            case 1:
            case 2:
                return "expectedDischargeDate"
            case 3:
            case 4:
                return "expectedComplianceDate"
            default:
                return "emptyLine";
        }
    }

    const setMessageRead = (messageId: number) => {
        try {
            portalService.setMessageRead(messageId);
            const messageReadIds = portalService.getMessageReadIds(auth.user.nameIdentifier);
            const _debtor: any = produce(debtor, (draft: any) => {
                draft.message = draft.message.filter((x: any) => messageReadIds.indexOf(x.messageId) === -1);
            });
            setDebtor(_debtor);
            auth.setAlertCount(_debtor.message.length);
        } catch (error) {
            const errorMsg = getErrorMessages(error.response);
            addToast(errorMsg, { appearance: "error", autoDismiss: true });
        }
    }

    const downloadDocument = async (fileName: string) => {
        try {
            setLoading(true)
            await documentService.downloadDocument(fileName);
        } catch (error) {
            const errorMsg = getErrorMessages(error.response);
            addToast(errorMsg, { appearance: "error", autoDismiss: true });
        } finally {
            setLoading(false);
        }
    }

    const deleteDocument = async (fileName: string) => {
        confirmAlert({
            title: intl.formatMessage({ id: "confirmation" }),
            message: intl.formatMessage({ id: "deleteDocumentConfirmMessage" }, { fileName: fileName }),
            buttons: [
                {
                    label: intl.formatMessage({ id: "yes" }),
                    onClick: async () => {
                        try {
                            setErrors(null);
                            setLoading(true);
                            await documentService.deleteDocument(fileName);
                            const _documents = produce(documents, (draft: any) => {
                                return draft.filter((x: { name: string }) => x.name !== fileName);
                            });
                            setDocuments(_documents);
                            const successMsh = intl.formatMessage({ id: "deleteDocumentSuccessMessage" }, { fileName: fileName });
                            addToast(successMsh, { appearance: "success", autoDismiss: true });
                        } catch (error) {
                            const errorMsg = getErrorMessages(error.response);
                            addToast(errorMsg, { appearance: "error", autoDismiss: true });
                        } finally {
                            setLoading(false);
                        }

                    }
                },
                {
                    label: intl.formatMessage({ id: "no" }),
                    onClick: () => { }
                }
            ]
        });

    }
    
    const onFileDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        try
        {
            if (rejectedFiles.length > 0) {
                // Handle the case where there are rejected files
                const errorMsg = intl.formatMessage({ id: "uploadDocumentsFailedMessageExtensionNotAllowed" });
                addToast(errorMsg, { appearance: "error", autoDismiss: true });
                return;
            }
            else
            {
                setLoading(true);
                setErrors(null);
                var formData = new FormData();
                for (var i = 0; i < acceptedFiles.length; i++) {
                    const file = acceptedFiles[i];
                    formData.append(`documents[${i}].index`, i.toString());
                    formData.append(`documents[${i}].file`, file);
                }
                const uploadResp = await documentService.uploadDocument(formData);
                const _documents = produce(documents, (draft: any) => {
                    draft.push(...uploadResp.data);
                });
                setDocuments(_documents);
                const successMsg = intl.formatMessage({ id: "uploadDocumentsSuccessMessage" });
                addToast(successMsg, { appearance: "success", autoDismiss: true });
            }
        } catch (error) {
            const errorMsg = intl.formatMessage({ id: "uploadDocumentsFailedMessage" });
            if (error.response != null && error.response.status  === 413) {
                const sizeErrorMsg = intl.formatMessage({ id: "uploadDocumentsFailedMessageSizeNotAllowed" });
                addToast(sizeErrorMsg, { appearance: "error", autoDismiss: true });
                return;
            }
            addToast(errorMsg, { appearance: "error", autoDismiss: true });
            const data = getErrorsData((error as AxiosError).response);
            setErrors(data);
        } finally {
            setLoading(false);
        }
    }, [documents])
            
    const { getRootProps, getInputProps } = useDropzone({
        accept: "application/msword, .docx, application/vnd.ms-excel, .xlsx, application/vnd.ms-powerpoint, .pptx, text/plain, application/pdf, image/jpg, image/jpeg, image/png",
        onDrop: onFileDrop
    })

    const formatCurrency = (amount?: number) => {
        return intl.formatNumber(amount || 0, { style: "currency", currency: "CAD", currencyDisplay: "narrowSymbol" });
    }

    if (pageLoading) {
        return (<Spinner />);
    }

    if (!debtor) {
        return (<Redirect to={AppRoute.AccessDenied} />);
    }

    return (
        <Segment basic style={{ padding: 0 }}>
            <Grid columns="equal" className="my-space" stackable >
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Alert
                            onDismiss={setMessageRead}
                            messages={debtor.message?.map((x: any) => { return { id: x.messageId, message: x.message1, priority: x.priorityCd } })}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Column verticalAlign='top'>
                    <Card fluid>
                        <Card.Content className="card-header">
                            <Card.Header><FormattedMessage id="myInformations" /></Card.Header>
                        </Card.Content>
                        <Card.Content className='top-card'>
                            <List>
                                <List.Item>
                                    <List.Icon name="user" size="large" color="blue" />
                                    <List.Content>{debtor.firstName} {debtor.lastName}</List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="map pin" size="large" color="blue" />
                                    <List.Content>
                                        {debtor.address &&
                                            <a href={`http://maps.google.com/maps?q=${debtor.address}`} target="_blank" rel="noopener noreferrer"> {debtor.address}</a>
                                        }
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="phone" size="large" color="blue" className="rotate" />
                                    <List.Content>
                                        {debtor.phone &&
                                            <a href={`tel:${debtor.phone}`}>{formatPhoneNumber(debtor.phone)}</a>
                                        }
                                    </List.Content>
                                </List.Item>
                                {debtor.debtorEmail.map((el: any, index: number) =>
                                    <List.Item key={index}>
                                        <List.Icon name="mail" size="large" color="blue" style={{ opacity: index === 0 ? 1 : 0 }} />
                                        <List.Content>
                                            <a className="email" href={`mailto:${el.email}`}>{el.email}</a>
                                        </List.Content>
                                    </List.Item>
                                )}
                                <List.Item>
                                    <List.Icon name="university" size="large" color="blue" />
                                    <List.Content>
                                        {debtor.osbNumber}
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Card.Content>
                    </Card>
                    <Card fluid>
                        <Card.Content className="card-header">
                            <Card.Header><FormattedMessage id="myImportantDates" /></Card.Header>
                        </Card.Content>
                        <Card.Content>
                            <List divided>
                                <List.Item>
                                    {debtor.firstCounsellingCompletedFg === true ?
                                        <List.Icon name="calendar check" size="large" className="greenCalendar" />
                                        :
                                        <List.Icon name="calendar" size="large" />
                                    }
                                    <List.Content>
                                        <Grid stackable className="very compact">
                                            <Grid.Column width="7">
                                                <List.Header style={{ display: 'inline' }}><FormattedMessage id="firstCounselling" />:</List.Header>
                                            </Grid.Column>
                                            <Grid.Column width="9">
                                                <List.Description style={{ display: 'inline' }}>
                                                    {debtor.firstCounsellingDate &&
                                                        <Moment format="YYYY-MM-DD">{debtor.firstCounsellingDate}</Moment>
                                                    }
                                                </List.Description>
                                            </Grid.Column>
                                        </Grid>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    {debtor.secondCounsellingCompletedFg === true ?
                                        <List.Icon name="calendar check" size="large" className="greenCalendar" />
                                        :
                                        <List.Icon name="calendar" size="large" />
                                    }
                                    <List.Content >
                                        <Grid stackable className="very compact">
                                            <Grid.Column width="7">
                                                <List.Header style={{ display: 'inline' }}><FormattedMessage id="secondCounselling" />: </List.Header>
                                            </Grid.Column>
                                            <Grid.Column width="9">
                                                <List.Description style={{ display: 'inline' }}>
                                                    {debtor.secondCounsellingDate &&
                                                        <Moment format="YYYY-MM-DD">{debtor.secondCounsellingDate}</Moment>
                                                    }
                                                </List.Description>
                                            </Grid.Column>
                                        </Grid>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="calendar" size="large" color="blue" />
                                    <List.Content>
                                        <Grid stackable className="very compact">
                                            <Grid.Column width="7">
                                                <List.Header style={{ display: 'inline' }}><FormattedMessage id="nextPayment" />: </List.Header>
                                            </Grid.Column>
                                            <Grid.Column width="9">
                                                <List.Description style={{ display: 'inline' }}>
                                                    {debtor?.case?.nextPaymentDate &&
                                                        <Moment format="YYYY-MM-DD">{debtor?.case?.nextPaymentDate}</Moment>
                                                    }
                                                    {debtor?.case?.nextPaymentAmount &&
                                                        <>
                                                            <span> <FormattedMessage id="amount" />: </span>
                                                            <b> {formatCurrency(debtor.case.nextPaymentAmount)}</b>
                                                        </>
                                                    }
                                                    {debtor?.case?.nextPaymentAmount && paymentType?.description &&
                                                        <span> {`(${paymentType?.description})`}</span>
                                                    }
                                                </List.Description>
                                            </Grid.Column>
                                        </Grid>
                                    </List.Content>
                                </List.Item>
                                {debtor?.insolvencyExpectedEndDate &&
                                    <List.Item>
                                        <List.Icon name="calendar" size="large" color="blue" />
                                        <List.Content>
                                            <Grid stackable className="very compact">
                                                <Grid.Column width="7">
                                                    <List.Header style={{ display: 'inline' }}>
                                                        <FormattedMessage id={getInsolvencyLabel(debtor?.case?.administrationTypeCd)} />:
                                                    </List.Header>
                                                </Grid.Column>
                                                <Grid.Column width="9">
                                                    <List.Description style={{ display: 'inline' }}>
                                                        <Moment format="YYYY-MM-DD">{debtor?.insolvencyExpectedEndDate}</Moment>
                                                    </List.Description>
                                                </Grid.Column>
                                            </Grid>
                                        </List.Content>
                                    </List.Item>
                                }
                            </List>
                        </Card.Content>
                    </Card>
                    {debtor.case?.totalInsolvencyAmount &&
                        <Card fluid>
                            <Card.Content className="card-header" >
                                <Card.Header><FormattedMessage id="myProgress" /></Card.Header>
                            </Card.Content>
                            <Card.Content>
                                <Popup
                                    content={<HtmlSpace>{formatCurrency(debtor.case?.realizedInsolvencyAmount)}</HtmlSpace>}
                                    position="top center"
                                    trigger={
                                        <Progress size="medium"
                                            percent={getPaymentProgress(debtor.case?.totalInsolvencyAmount, debtor.case?.realizedInsolvencyAmount)}
                                            progress
                                            indicating
                                            precision={2}
                                            label={
                                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 5px' }}>
                                                    <small><HtmlSpace>{formatCurrency(0)}</HtmlSpace></small>
                                                    <small><HtmlSpace>{formatCurrency(debtor.case?.totalInsolvencyAmount)}</HtmlSpace></small>
                                                </div>
                                            }
                                        />
                                    } />
                                <List>
                                    <List.Item>
                                        <List.Icon name="currency" size="big" color="blue" />
                                        <List.Content>
                                            <Grid stackable className="very compact">
                                                <Grid.Column width="7">
                                                    <List.Header style={{ display: 'inline' }}><FormattedMessage id="totalAmountRemaining" />:</List.Header>
                                                </Grid.Column>
                                                <Grid.Column width="9">
                                                    <List.Description style={{ display: 'inline' }}>
                                                        {formatCurrency((debtor.case?.totalInsolvencyAmount || 0) - (debtor.case?.realizedInsolvencyAmount || 0))}
                                                    </List.Description>
                                                </Grid.Column>
                                            </Grid>
                                        </List.Content>
                                    </List.Item>
                                </List>
                            </Card.Content>
                        </Card>
                    }
                </Grid.Column>
                <Grid.Column verticalAlign='top'>
                    <Card fluid>
                        <Card.Content className="card-header">
                            <Card.Header><FormattedMessage id="myCounsellor" /></Card.Header>
                        </Card.Content>
                        <Card.Content className='top-card'>
                            <List>
                                <List.Item>
                                    <List.Icon name="user" size="large" color="blue" />
                                    <List.Content>{debtor.case?.counsellor?.fullName}</List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="map pin" size="large" color="blue" />
                                    <List.Content>
                                        {debtor.case?.office?.address &&
                                            <a href={`http://maps.google.com/maps?q=${debtor.case?.office?.address}`} target="_blank" rel="noopener noreferrer">{debtor.case?.office?.address}</a>
                                        }
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="phone" size="large" color="blue" className="rotate" />
                                    <List.Content>
                                        {debtor.case?.counsellor?.phone &&
                                            <a href={`tel:${debtor.case?.counsellor?.phone}`}>{formatPhoneNumber(debtor.case?.counsellor?.phone)}</a>
                                        }
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    <List.Icon name="mail" size="large" color="blue" />
                                    <List.Content>
                                        <a className="email" href={`mailto:${debtor.case?.counsellor?.email}`}>{debtor.case?.counsellor?.email}</a>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Card.Content>
                    </Card>
                    <Card fluid>
                        <Card.Content className="card-header" >
                            <Card.Header><FormattedMessage id="myDocuments" /></Card.Header>
                        </Card.Content>
                        <Card.Content style={{ padding: 0 }}>
                            <Form loading={loading} error={errors !== null}>
                                {documents.length > 0 &&
                                    <List divided style={{ margin: "1.5em 2em 3em" }}>
                                        {documents.map((document: any, index: number) =>
                                            <List.Item key={index} style={{ paddingBottom: "1em" }}>
                                                <List.Icon name="file alternate" size="large" color="blue" />
                                                <List.Content style={{ paddingTop: 0 }}>
                                                    <List.Header style={{ paddingBottom: "0.4em" }}>
                                                        {document.name}
                                                    </List.Header>
                                                    <List.Description>
                                                        <Moment format="YYYY-MM-DD">{document.createdOn}</Moment> - <FormattedMessage id="fileSize" values={{ size: document.fileSize }} />
                                                    </List.Description>
                                                </List.Content>
                                                <List.Content floated="right" style={{ display: "flex", alignItems: "center" }}>
                                                    {!isMobile &&
                                                        <Button onClick={() => downloadDocument(document.name)} className="rounded-button">
                                                            <FormattedMessage id="download" />
                                                        </Button>
                                                    }

                                                    <Popup on="click" hideOnScroll closeOnPortalMouseLeave closeOnTriggerClick
                                                        position="bottom center"
                                                        trigger={
                                                            <Icon link name="ellipsis vertical" />
                                                        }>
                                                        <Menu secondary vertical size="small">
                                                            {isMobile &&
                                                                <Menu.Item
                                                                    onClick={() => downloadDocument(document.name)}>
                                                                    <Icon name="download" color="blue"
                                                                        style={{ float: "none", marginLeft: -5, marginRight: 5 }} />
                                                                    <FormattedMessage id="download" />
                                                                </Menu.Item>
                                                            }

                                                            <Menu.Item disabled={!document.uploadedByUser}
                                                                onClick={() => deleteDocument(document.name)}>
                                                                <Icon name="trash alternate" color="red"
                                                                    style={{ float: "none", marginLeft: -5, marginRight: 5 }} />
                                                                <FormattedMessage id="delete" />
                                                            </Menu.Item>
                                                        </Menu>
                                                    </Popup>
                                                </List.Content>
                                            </List.Item>
                                        )}
                                    </List>
                                }
                                <MessageError errors={errors} style={{ marginBottom: 0, boxShadow: "none" }} />
                                <div {...getRootProps()} className="dropzone">
                                    <input {...getInputProps()} />
                                    {
                                        <p><FormattedMessage id="dropzoneMessage" values={{br: <br />}}  /></p>
                                    }
                                </div>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}

export default MySpace
