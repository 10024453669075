import React from 'react'
import { Message, Icon } from 'semantic-ui-react'

export interface AlertMessage {
    id: number;
    message: string;
    priority: number;
}

interface Props {
    messages: Array<AlertMessage>;
    onDismiss: (messageId: number) => void;
}

const Alert: React.FC<Props> = (props: Props) => {
    const getColor = (priority: number) => {
        switch (priority) {
            case 1:
                return "red";
            case 2:
                return "yellow";
            case 3:
                return "blue";
        }
        return "blue";
    }

    const getIcon = (priority: number) => {
        switch (priority) {
            case 1:
                return "warning circle";
            case 2:
                return "warning sign";
            case 3:
                return "flag";
        }
        return "flag";
    }

    return (
        <div>
            {
                props.messages?.map((msg, index) =>
                    <Message key={index} className="alert" color={getColor(msg.priority)} onDismiss={() => props.onDismiss(msg.id)}>
                        <Icon name={getIcon(msg.priority)} size="large" />
                        <span dangerouslySetInnerHTML={{ __html: msg.message }}></span>
                    </Message>
                )
            }
        </div>
    )
}

export default Alert
