import axios from 'axios';
import { axiosDownload } from '../utils/Utils';

export const getCurrentUserDocuments = async () => {
    return await axios.get(`/document`);
}

export const uploadDocument = async (file: FormData) => {
    return await axios.post(`/document`, file, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
};

export const downloadDocument = async (fileName: string) => {
    const response = await axios.post(`/document/${fileName}/download`, null, {
        responseType: 'blob'
    });
    axiosDownload(response);
}

export const deleteDocument = async (fileName: string) => {
    await axios.delete(`/document/${fileName}`);
}

